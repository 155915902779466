@font-face {
  font-family: 'Messina Sans';
  src: url('../fonts/MessinaSans-Regular.woff2') format('woff2'),
      url('../fonts/MessinaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueThin.woff2') format('woff2'),
      url('../fonts/HelveticaNeueThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueLight.woff2') format('woff2'),
      url('../fonts/HelveticaNeueLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueRegular.woff2') format('woff2'),
      url('../fonts/HelveticaNeueRegular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueMedium.woff2') format('woff2'),
      url('../fonts/HelveticaNeueMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('../fonts/MessinaSans-Light.woff2') format('woff2'),
      url('../fonts/MessinaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('../fonts/SFRounded-Ultralight.woff2') format('woff2'),
      url('../fonts/SFRounded-Ultralight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('../fonts/SFRounded-Ultralight.woff2') format('woff2'),
      url('../fonts/SFRounded-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

:root {
  --bs-body-bg: #000;
  --bs-body-color: #fff;
  --bs-white-rgb: 255, 255, 255;
  --color-white: #fff;
  --font-helvetica: 'HelveticaNeue';
  --font-messina: 'Messina Sans';
  --font-sfpro: 'SF Pro Rounded';
}

* {
  box-sizing: border-box;
}
html.lenis.lenis-smooth {
  scroll-behavior: auto!important;
}
body{
  font-family: var(--font-helvetica);
  font-size: 18px;
  font-weight: 300; 
  height: 100vh;
  background-color: #131313;
  color: var(--color-white);
}
body::-webkit-scrollbar {
  height: 0;
  width: 0
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--font-sfpro);
  font-weight: 100;
  letter-spacing: 0.05px;
  margin-top: 0;
  margin-bottom: 20px;
}
h1,h2{
  font-size: 32px;
  line-height: 38px;
}
h3{
  font-size: 28px;
  line-height: 34px;
}
h4{
  font-size: 22px;
  line-height: 28px;
}
h6{
  font-family: var(--font-messina);
  font-size: 16px;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
}
.list-style-none{
  list-style: none;
}
p{
  margin-top: 0;
  margin-bottom: 15px;
}
p:last-of-type{
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
.text-center{
  text-align: center;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
img{
  max-width: 100%;
  display: block;
}
.overlay-15::before {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  top: 0;
  left: 0;
  content: "";
  opacity: 15%;
}
.letter-spacing-5 {
  letter-spacing: 0.05em;
}
.letter-spacing-20 {
  letter-spacing: 0.2em;
}
.font-helvetica{
  font-family: var(--font-helvetica);
}
.font-messina,
body .react-datepicker{
  font-family: var(--font-messina);
}
.font-sfpro{
  font-family: var(--font-sfpro);
}

.text-uppercase{
  text-transform: uppercase;
}
.text-white{
  color: #fff;
}
.text-dark,
.text-dark h6,
.text-dark h2,
.text-dark p{
  color: #000;
}
.bg-white {
  background-color: #fff;
}
.font-38{
  font-size: 38px;
  line-height: 46px;
}
.font-26{
  font-size: 26px;
  line-height: 32px;
}
.font-22{
  font-size: 22px;
  line-height: 28px;
}
.font-18{
  font-size: 18px;
  line-height: 35px;
}
.font-14{
  font-size: 14px;
  line-height: 18px;
}
.font-12{
  font-size: 12px;
  line-height: 15px;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.p-0{
  padding: 0;
}
.ptb-200{
  padding-top: 200px;
  padding-bottom: 200px;
}
.ptb-120{
  padding-top: 120px;
  padding-bottom: 120px;
}
.pb-120{
  padding-bottom: 120px;
}
.pt-50{
  padding-top: 50px;
}
.pt-40{
  padding-top: 40px;
}
.pt-200{
  padding-top: 200px;
}
.plr-100{
  padding-left: 100px;
  padding-right: 100px;
}
.mt-200{
  margin-top: 200px;
}
.m-0{
  margin: 0px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-40{
  margin-bottom: 40px;
}
.flex-gap-20{
  gap: 20px;
}
.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
}
.top-0{
  top: 0;
} 
.start-0{
  left: 0;
}
.z-1{
  z-index: 1;
}
.z-2{
  z-index: 2;
}
.overflow-hidden{
  overflow: hidden;
}
.overflowx-hidden{
  overflow-x: hidden;
}
.showonmobile{
  display: none;
}
.light_green_dot {
  height: 5px;
  width: 5px;
  background: #A2BB3C;
  display: inline-block;
  border-radius: 50%;
}
.object-fit-cover{
  object-fit: cover;
}

/* Flex Classes */
.d-flex{
  display: flex;
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}
.nowrap{
  flex-wrap: nowrap;
}
.row-reverse{
  flex-flow: row-reverse;
}
.flex-column{
  flex-direction: column;
}
.d-grid{
  display: grid;
}

/* Common Form style */
div .btn-primary,
div .btn-primary:hover,
div .btn-primary:focus,
div .btn-primary:active,
:not(.btn-check)+.btn:active,
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{  
  background-color: rgba(var(--bs-white-rgb),0.25);
  border: none;
  font-size: 14px;
  font-family: var(--roboto-font);
  line-height: 18px;
  padding: 19px 20px;
  min-width: 200px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  --bs-bg-opacity: 1;  
  letter-spacing: 0.15em;
  border-radius: 50px;
  text-transform: uppercase;
}
div .btn-primary:hover{
  background-color: rgba(var(--bs-white-rgb),0.35);
}
label.form-label {
  font-weight: 500;
  font-family: var(--roboto-font);
  letter-spacing: 0.02em;
}
.form-check-input[type=radio],
.form-check-input[type=checkbox]{
  display: none;
}
.form-check-input[type=radio] + label,
.form-check-input[type=checkbox] + label{
    background-color: rgba(var(--bs-white-rgb),0.25);
    border: none;
    font-size: 12px;
    font-family: var(--roboto-font);
    line-height: 18px;
    padding: 17px 39px 14px;
    min-width: 200px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    --bs-bg-opacity: 1;
    letter-spacing: 0.15em;
    border-radius: 50px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}
.form-check-input[type=radio]:checked + label,
.form-check-input[type=checkbox]:checked + label {
  background: rgba(var(--bs-white-rgb),0.5);
  font-weight: 600;
}
input:focus,textarea:focus{
  outline: none;
}
button{
  cursor: pointer;
}

/* Header */
header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
header.sticky {
  background: rgba(0,0,0,0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: sticky;
  top:0;
  z-index: 9;
}
.header_top {
  width: 100%;
  text-align: center;
  padding: 30px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.sticky a.header_logo img {
  max-height: 17px;
}
header .header_top nav {
  margin-top: 10px;
  padding: 0 30px px;
}
header .header_top nav > ul {
  margin: 0;
  padding: 0;
}
header .header_top nav > ul > li {
  position: relative;
  display: flex;
  align-items: center;
}
header .header_top nav > ul > li.active::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  background: #A2BB3C;
  border-radius: 50%;
  bottom: 11px;
  left: 0;
  right: 0;
  margin: auto;
}
header .header_top nav > ul > li:not(:last-child) {
  margin-right: 40px;
}
header .dropdown_menu {
  width: 170px;
  position: absolute;
  padding: 12px;
  top: 60px;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
}
header .header_top nav > ul > li.expanded .dropdown_menu{
  top: 41px;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
header:not(.sticky) .header_top nav > ul > li.expanded .dropdown_menu {
  top: 30px;
}
header .dropdown_menu ul{
  padding: 0;
}
header .dropdown_menu li:not(:last-child) {
  margin-bottom: 10px;
}
header .dropdown_menu li, header .dropdown_menu li a {
  display: block;
  text-align: left;
}
header .header_top nav > ul > li span.menuicon {
  display: inline-block;
  width: 16px;
  margin-left: 4px;
  cursor: pointer;
}
header .header_top nav > ul > li span.menuicon img{
  transition: all ease 0.3s;
}
header .header_top nav > ul > li.expanded img {
  transform: rotate(180deg);
}
.hamburger {
  display: none;
}
.hamburger .line{
  width: 35px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(2){
  opacity: 0;
}
.hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}
.hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}

/* Mobile Menu */
.mobile_menu_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  padding: 0 50px 0 0;
  transition: all ease 0.3s;
  transform: translateX(-100%);
}
.mobile_menu_sidebar.show_menu_bar {
  transform: none;
}
.mobile_menu_wrapper {
  padding: 40px;
  background: #181818;
  height: 100%;
  position: relative;
}
.mobile_menu_wrapper nav ul {
  flex-direction: column;
  padding: 0;
  gap: 10px;
}
.mobile_menu_wrapper nav ul li {
  display: flex;
  flex-wrap: wrap;
}
.mobile_menu_wrapper .menuicon {
  display: block;
  width: 18px;
}
.mobile_menu_wrapper .dropdown_menu {
  position: static;
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 100%;
  background: transparent;
}
.mobile_menu_wrapper .expanded .dropdown_menu {
  opacity: 1;
  visibility: visible;
  height: auto;
  padding: 0 0 0 10px;
}
.close_menu_bar {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  height: auto;
  cursor: pointer;
}
.close_menu_bar .line {
  display: block;
  width: 35px;
  height: 2px;
  background: #fff;
  margin: 5px 0;
}
.close_menu_bar .line:nth-child(1){
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -o-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.close_menu_bar .line:nth-child(2){
  -webkit-transform: translateY(-0px) rotate(-45deg);
  -ms-transform: translateY(-0px) rotate(-45deg);
  -o-transform: translateY(-0px) rotate(-45deg);
  transform: translateY(-0px) rotate(-45deg);
}

/* Home */
.page_banner picture img{
  height: 100vh;
  object-fit: cover;
  width: 100%;
  min-height: 600px;
  object-position: bottom center;
}
.main_home_banner::before {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  top: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 10%, rgba(0, 0, 0, 0) 65%, #131313);
  top: 0;
}
.main_home_banner_content {
  top: 0;
  left: 0;
  padding-top: 158px;
}
.bannerbox {
  position: absolute;
  height: calc(100% - 100px);
  max-width: 415px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 0px 22px 70px 10px #000000d9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_banner_bottom {
  margin: 0 -50%;
  padding: 0 26%;
}
.dotdivider span {
  height: 5px;
  width: 5px;
  background: #A2BB3C;
  display: inline-block;
  border-radius: 50%;
  margin: 35px 0;
}
.dotdivider.big span {
  height: 10px;
  width: 10px;
}
.slider_wrapper .swiper {
  padding: 66px 0 130px;
}
.featured_work_slider .swiper-slide{
  transition: all ease 0.3s;
  position: relative;
  z-index: 1;
}
.featured_work_slider  .swiper-slide.swiper-slide-active {
  transform: scale(1.2);
  position: relative;
  z-index: 2;  
}
.featured_work_slider  .swiper-slide.swiper-slide-active:hover {
  transform: scale(1.25);
}
.common_work_slide {
  height: 500px;  
}
.work_slide_hover_content {
  bottom: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
}
.featured_work_slider .swiper-slide .common_work_slide > img {
  opacity: 35%;
  object-fit: cover;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide > img {
  opacity: 100%;
}
.swiper-slide.swiper-slide-active .common_work_slide_text::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 50%;
  top: 0;
  left: 0;
  z-index: -1;
}
.common_work_slide_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
  justify-content: center;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
  padding: 105px 0px 155px;
}
.workbox_head {
  margin: 0 -40px;
}
.common_work_slide h2 {
  font-size: 28px;
  line-height: 32px;
}
.common_work_slide_text  p {
  font-size: 10px;
  text-transform: uppercase;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text .work_slide_hover_content {
  display: flex;
}
.featured_work_slider .swiper-slide.swiper-slide-active svg.showdefault {
  display: none;
}
.common_work_slide svg.showdefault {
  margin-top: 30px;
}
.common_work_slide svg.showonhover {
  margin-bottom: 28px;
}
.common_slider_arrow {
  height: 45px;
  width: 45px;
  flex: 0 0 45px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.5);
  cursor: pointer;
}
.slider_nav {
  margin: 20px 0;
  gap: 40px;
}
.slider_scrollbar.swiper-scrollbar-horizontal {
  height: 2px;
  width: 100%;
  max-width: 245px;
}
.slider_scrollbar.swiper-scrollbar-horizontal {
  height: 2px;
  max-width: 245px;
  background: rgba(245,245,245,0.3);
}
.slider_scrollbar .swiper-scrollbar-drag {
  background: rgba(245,245,245,0.75);
}
.project_link {
  margin-bottom: -40px;
  position: absolute;
  bottom: -12px;
  display: none;
}
.featured_work_slider .swiper-slide.swiper-slide-active .project_link{
  display: flex;
}
.underline_link::after {
  width: 65px;
  height: 0.5px;
  background: #fff;
  content: "";
  display: block;
  margin: 7px 0 0;
}

/* Home Gallery */
.home_gallery_wrapper{
  max-width: 910px;
  margin: 0 auto;
}
.home_gallery_image img, .home_gallery_image video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.home_gallery_middle{
  margin-top: -245px;
  gap: 10px;
}
.home_gallery_top .home_gallery_image.gallery_image_1 {
  width: 316px;
  height: 444px;
  margin: auto;
}
.home_gallery_image.gallery_image_1::after {
  position: absolute;
  content: "";
  height: 85%;
  background: linear-gradient(00deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  bottom: 0;
}
.gallery_image_2 {
  width: 100%;
  max-width: 330px;
  height: 313px;  
}
.gallery_image_3{
  width: 100%;
  max-width: 375px;
  height: 282px;
  margin-top: 183px;
}
.gallery_image_4 {
  width: 152px;
  height: 213px;
  margin-left: 280px;
  margin-top: 0px;
}
.home_gallery_image  .viewalllink {
  bottom: 40px;
  z-index: 1;
  left: 30px;
}
.home_gallery_image .viewalllink::after {
  width: 95px;
  margin: 10px 0 0;
}

/* Service Slider */
.common_service_slide {
  height: 620px;
  opacity: 20%;
}
.common_service_slide img{  
  object-fit: cover;
}
.swiper-slide-active .common_service_slide{
  opacity: 100%;
}
.common_service_slide_text {
  padding: 100px 40px 40px;
}
.common_service_slide_text::after {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
}
.common_service_slide_text  h2 {
  margin-bottom: 35px;
  font-size: 35px;
}
.common_service_slide_text ul.list-style-none li:not(:last-child) {
  margin-bottom: 23px;
  letter-spacing: 0.05px;
}
.common_service_slide_text  ul.list-style-none {
  display: none;
}
.swiper-slide.swiper-slide-active .common_service_slide_text  ul.list-style-none {
  display: block;
}
.services_slider > h6 {
    margin-bottom: 56px;
}
.services_slider .slider_nav {
    margin-top: 35px;
}
.common_service_slide_text {
  padding: 100px 30px;
}

.follow_milkbar {
  max-width: 850px;
  margin: auto;
}
.follow_media_grid{
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.follow_milkbar_top_inner {
  gap: 30px;
  margin-bottom: 40px;
  padding: 0 15px;
}
a.follow_button {
  padding: 10px 28px 10px 28px;
  border-radius: 25px;
  background: rgba(255,255,255,0.1);
  color: #fff;
  border: 0.5px solid #575757;
  font-size: 14px;
  letter-spacing: 0.05px;
  display: inline-block;
}

/* About Page */
.inner_page_banner::before,
.inner_page_banner::after{
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
}
.inner_page_banner::before{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  height: 30%;
  top: 0;    
}
.inner_page_banner::after {
  background: linear-gradient(0deg, rgb(19 19 19) 10%, rgba(0, 0, 0, 0) 100%);
  height: 50%;
  bottom: 0;
}
.about_content {
  max-width: 750px;
  margin: -180px auto 0;
  padding: 0 15px;
}

/* contact page */
.contact_form_wrap {
  max-width: 900px;
  margin: -180px auto 0;
}
.option_types_radios {
  max-width: 630px;
  margin: auto;
  gap: 12px;
}
.contact_form_wrap .form-group {
  text-align: center;
  margin-bottom: 45px;
}
.contact_form_wrap .form-group.textareafld {
  padding: 40px 0;
}
.option_types_radios input[type=checkbox] + label {
  padding: 20px 45px 17px;
  font-size: 18px;
  display: inline-block;
  font-family: 'SF Pro Rounded';
  text-transform: inherit;
  letter-spacing: 0.05px;
  min-width: auto;
  height: auto;
  background: rgba(255,255,255,0.10);
}
.contact_form_wrap .form-group input, .contact_form_wrap .form-group textarea {
  max-width: 360px;
  width: 100%;
  background: transparent;
  border-width: 0 0 0.6px 0;
  font-size: 18px;
  font-family: 'SF Pro Rounded';
  letter-spacing: 0.05px;
  margin: 0 auto;
  color: #fff;
}
.contact_form_wrap .form-group input[type="text"],
.contact_form_wrap .form-group input[type="email"] {
  height: 46px;
  vertical-align: top;
  line-height: 1;
  text-align: center;
  padding-bottom: 25px;
  color: #fff;
  font-size: 18px;
  font-family: 'SF Pro Rounded';
}
.contact_form_wrap .form-group textarea{
  height: 120px;
}
.contact_form_wrap  button {
  background: transparent;
  border: none;
  font-size: 16px;
}
.contact_form_wrap  button::after {
  width: 95px;
  margin: 10px 0 0;
}
.message  p.font-18 {
  margin: 5px 0 10px;
}

/* Project Page */
.project_banner .bannerbox {
  align-items: flex-end;
  padding-bottom: 60px;
}
.project_banner .bannerbox .home_banner_bottom {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.project_banner .bannerbox .home_banner_bottom h1 {
  margin-bottom: 33px;
}
.brand_direction {
  max-width: 510px;
  margin: 80px auto 0;
  padding: 0 15px;
}
.brand_direction  p {
  font-size: 18px;
  line-height: 30px;
}
.brand_direction  .brand_logo {
  margin-top: 110px;
  gap: 30px
}
.common_brand_logo:not(.active) {
  opacity: 40%;
}
.seamless_booking_head{
  max-width: 460px;
  margin: 0 auto;
}
.dotdivider.mtb15 span {
  margin: 15px 0;
}
.seamless_booking_slider_wrapper .swiper {
  padding: 50px 0;
}
.seamless_booking_slider_wrapper .swiper-slide {
  transition: all ease 0.3s;
  position: relative;
  z-index: 1;
}
.seamless_booking_slider_wrapper .swiper-slide.swiper-slide-active {
  transform: scale(1.2);
  position: relative;
  z-index: 2;
}
.more_project_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
  padding-bottom: 40px;
  justify-content: flex-end;
}
.more_project_slider .swiper-slide.swiper-slide-active .common_work_slide_text::after {
  height: 40%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  top: auto;
  bottom: 0;
}
.project_verticle_images{
  max-width: 910px;
  padding: 0 15px;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
}
.project_verticle_images .project_verticle_image:first-child{
  padding-right: 140px;
}
.project_verticle_images .project_verticle_image:last-child {
  padding-left: 140px;
  margin-top: 215px;
}
.verticle_overlap_text {
  background-color: rgba(0,0,0,0.3);
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 270px;
  border-radius: 5px;
  padding: 26px 30px;
}
.project_verticle_images .project_verticle_image:first-child .verticle_overlap_text{
  top: 80px;
  right: 0px;
}
.project_verticle_images .project_verticle_image:last-child .verticle_overlap_text{
  top: 230px;
  left: 0px;
}
.verticle_overlap_text p {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}
.project_verticle_image  img {
  border-radius: 10px;
  overflow: hidden;
}


/* Social Media */
.text_block{
  max-width: 690px;
  padding: 0 20px;
  margin: 0 auto;
}
.common_social_slider {
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
}
.common_social_slider > div{
  flex: 0 0 50%;
  max-width: 50%;
}
.social_slider_slide {
  height: 500px;
  border-radius: 6px;
  overflow: hidden;
}
.rtlSlider-swiper-custom-scrollbar {
  direction: rtl;
}
.common_social_slider .slider_scrollbar.swiper-scrollbar-horizontal {
    max-width: 225px;
    margin-top: 45px;
}
.rtlSlider-swiper-custom-scrollbar.slider_scrollbar {
  margin-left: auto;
}
.social_ltrslider_outer .social_ltrslider_left,
.social_rtlslider_outer .social_rtlslider_left {
  padding: 8% 9% 0 9%;
}
.common_social_slider p {
  font-size: 18px;
  line-height: 25px;
}
.envisioning {
  margin-top: 150px;
}
.social_media_page h6.workhead {
  text-align: center;
  padding: 0 20px;
  margin: 100px 0 0 0;
  display: none;  
}

/* Work Page */
.project_list_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 40px;
  column-gap: 10px;
}
.project_list_wrapper .project_link {
    display: block;
}
.project_list_wrapper > div {
  margin-bottom: 60px;
}
.project_list_wrapper .common_work_slide::after {
  position: absolute;
  content: "";
  height: 100%;
  width: calc(100% - 80px);
  background: #000;
  opacity: 50%;
  top: 0;
  left: 40px;
}
.project_list_wrapper .common_work_slide {
  padding: 0 40px;
}
.project_list_wrapper .common_work_slide img {
  width: calc(100% - 80px);
  left: 40px;
}
.project_list_wrapper .common_work_slide_text {
  left: 0;
}
.project_list_wrapper .workbox_head {
  margin: 0;
}

/* Service Tabs Section */
.services_tabs_panel {
  padding-left: 100px;
}
.service_tabs_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.service_tab_images, .service_tab_items {
  flex: 0 0 auto;
  width: 50%;
}
.service_tab_items {
  display: flex;
  align-items: center;
}
.service_tabs_wrapper .swiper.swiper-vertical {
  height: auto;
  margin: 0;
}
.service_tabs_wrapper .swiper-slide {
  height: auto !important;
}
.service_tab_images .swiper-slide {
  display: flex;
  align-items: center;
}
.service_tab_item {
  display: flex;
  column-gap: 50px;
}
.service_desc {
  display: flex;
  gap: 20px;
  max-width: 300px;
}
.service_desc .icon {
  display: block;
  width: 50px;
  height: auto;
}
.service-name h3 {
  font-size: 30px;
  line-height: 30px;
  transition: all ease 0.3s;
  opacity: 0.6;
  font-weight: 200;
}
.service_desc {
  display: flex;
  gap: 20px;
  max-width: 300px;
  transition: all ease 0.3s;
  opacity: 0;
  visibility: hidden;
}
.service_desc .text strong {
  font-weight: 500;
}
.swiper-slide-thumb-active .service_desc {
  opacity: 1;
  visibility: visible;
}
.swiper-slide-thumb-active .service-name h3 {
    opacity: 1;
}
.services_tabs_panel .slider_nav {
  display: none;
}

/* Responsive Css */
@media(max-width: 1024px) and (min-width: 769px){
.featured_work_slider .workbox_head {
    margin: 0px;
}
}

@media(max-width: 1280px){
  .project_list_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media(max-width: 1024px){
.project_list_wrapper.plr-100 {
  padding-left: 50px;
  padding-right: 50px;
}
}
@media(max-width: 992px){
  .project_verticle_images .project_verticle_image:first-child {
    padding-right: 70px;
  }
  .project_verticle_images .project_verticle_image:last-child {
    padding-left: 70px;
  }
}

@media(max-width: 768px){
  .ptb-120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pb-120{
    padding-bottom: 80px;
  }
  .plr-100 {
    padding-left: 15px;
    padding-right: 15px;
  }
  h1, h2{
    font-size: 28px;
  }
  .showonmobile{
    display: block;
  }
  header .header_top nav {
    display: none;
  }
  .header_contact {
      margin: 0 20px 0 auto;
  }
  .hamburger {
    display: block;
  }
  .featured_work_slider .swiper-slide .common_work_slide > img {
    max-width: 65%;
    margin: auto;
    left: 0;
    right: 0;
  }
  .featured_work_slider  .swiper-slide.swiper-slide-active {
    transform: scale(1);
  }
  .swiper-slide.swiper-slide-active .common_work_slide_text::after {
    width: calc(100% - 35%);
    background: #000;
    right: 0;
    margin: auto;
  }
  .common_work_slide {
    margin-bottom: 124px;
  }
  .featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
    padding: 105px 10px 155px;
  }
  .featured_work_slider {
    padding-bottom: 0;
  }
  .home_gallery_block {
    padding-bottom: 50px;
  }
  .main_home_banner_content  .plr-100 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .home_banner_bottom  h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .home_banner_bottom {
      padding: 0 32%;
      margin: 0 -70px;
  }
  .brand_direction {
    margin: 50px auto 0;
  }
  .brand_direction  .brand_logo {
    margin-top: 50px;
    margin-bottom: 50px;
  } 
  .about_top_banner .bannerbox {
    background: #00000040;
  }
  .contact_top_banner .bannerbox {
    box-shadow: 0px 22px 70px 4px #0000008F;
  }
  .about_top_banner .bannerbox h1,
  .contact_top_banner .bannerbox h1{
    font-size: 26px;
  }
  .contact_form_wrap .form-group.textareafld {
    padding: 0;
  }
  .about_content {
      margin: -180px auto 50px;
  }
  .social_media_page .what_we_do {
    margin-bottom: 50px;
  }
  .common_social_slider {
    padding-top: 100px;
  }  
  .font-18 {
    font-size: 16px;
    line-height: 23px;
  }
  .text_block {
    padding: 0 30px;
  }
  .envisioning {
    margin-top: 100px;
  }
  .social_media_page .home_gallery_block {
    padding-bottom: 0;
  }
  .social_media_page h6.workhead {
    display: block;  
  }
  .featured_work_slider  .swiper-slide.swiper-slide-active:hover, 
  .seamless_booking_slider_wrapper .swiper-slide.swiper-slide-active {
    transform: none;
  }
  .seamless_booking_slider_wrapper .swiper {
    padding: 0;
  }
  .seamless_booking_slider_wrapper {
    margin-right: -15px;
  }
  .featured_work_slider.more_project_slider {
    padding-bottom: 100px;
  }
  .follow_milkbar_top_inner {
    gap: 20px;
  }
  .follow_media_grid {
    gap: 4px;
  }
  .project_list_wrapper.plr-100 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .project_list_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .project_list_wrapper .common_work_slide {
    margin-bottom: 0px;
  }
  .services_tabs_panel {
    padding-left: 0;
  }
  .services_tabs_panel > h6 {
      text-align: center;
      margin: 0 0 50px;
  }
  .service_tab_images, .service_tab_items {
      flex: 0 0 auto;
      width: 100%;
  }
  .service_desc {
      display: flex;
      gap: 20px;
      max-width: 300px;
      transition: all ease 0.3s;
      opacity: 0;
      visibility: hidden;
      display: none;
  }
  .service_tab_images .swiper-slide {
    flex-direction: column;
    text-align: center;
  }
  .service_tab_item .service-name {
    text-align: center;
    width: 100%;
    position: relative;
  }
  .service_tab_item .service-name:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #a2bb42;
    transition: all ease 0.3s;
    opacity: 0;
}
  .swiper-slide-thumb-active .service_tab_item .service-name:after {
    opacity: 1;
  }
  .service_tab_item .service-name h3 {
      font-size: 18px;
      line-height: 22px;
  }
  .mobile_service_content {
    width: 100%;
    padding: 20px 10px 30px;
  }
  .mobile_service_content .service-name h3 {
      opacity: 1;
  }
  .mobile_service_content .service_desc {
      display: block;
      opacity: 1;
      visibility: visible;
      margin: 0 auto;
  }
  .services_tabs_panel .slider_nav {
      display: flex;
      width: 100%;
  }
}

@media(max-width: 645px){
  .bannerbox {
    max-width: 270px;
    margin-top: 30px;
    height: calc(100% - 238px);
  }
  .main_home_banner picture img,
  .inner_page_banner picture img {
    height: auto;
  }
  .what_we_do {
    margin-top: -161px;
  }
  .option_types_radios input[type=radio] + label {
    padding: 16px 25px 15px;
  }
  .project_verticle_images {
    grid-template-columns: repeat(1, 1fr);
  }
  .project_verticle_images .project_verticle_image {
    max-width: 430px;
    width: 100%;
  }
  .project_verticle_images .project_verticle_image:last-child {
    margin-top: 50px;
    margin-left: auto;
  }
  .project_verticle_images .project_verticle_image:last-child img {
    margin-left: auto;
  }
}

@media(max-width: 575px){
  .home_gallery_top .home_gallery_image.gallery_image_1 {
    width: 208px;
    height: 292px;
  }
  .gallery_image_2 {
    max-width: 185px;
    height: 229px;
  }
  .gallery_image_3 {
    max-width: 247px;
    height: 187px;
    margin-top: 183px;
  }
  .gallery_image_4 {
    width: 100px;
    height: 140px;
    margin-left: 75px;
  }
  .home_gallery_middle {
    margin-top: -145px;
  }
  .workbox_head {
    margin: 00px;
  }
  .common_social_slider {
    flex-direction: column-reverse;
  }
  .common_social_slider .swiper-slide:not(.swiper-slide-active) .social_slider_slide {
    height: calc(500px - 40px);
    margin-top: 40px;
    transition: all ease 0.3s;
  }
  .common_work_slide h2 {
    font-size: 26px;
  }
  .bannerbox {
    max-width: 270px;
  }
  .social_ltrslider_outer .social_ltrslider_left, .social_rtlslider_outer .social_rtlslider_left {
    padding: 20px 30px 0;
    text-align: center;
  }
  .common_social_slider .slider_scrollbar.swiper-scrollbar-horizontal {
    margin-left: auto;
    margin-right: auto;
  }
  .common_social_slider h3 {
    font-size: 26px;
  }
  .common_social_slider p {
    font-size: 16px;
    line-height: 23px;
  }
  .social_ltrslider_outer.common_social_slider1 {
    padding-top: 40px;
  }
  .common_social_slider > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .option_types_radios input[type=checkbox] + label {
      padding: 17px 25px 17px;
  }
  .project_list_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .project_list_wrapper.plr-100 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media(max-width: 480px){
  .common_service_slide_text:after {
      background: #000000;
      opacity: 50%;
  }
  .services_slider .slider_nav {
    margin-top: -60px;
    position: relative;
    z-index: 1;
    padding: 0 15px;
  }
  .home_banner_bottom {
    padding: 0;
  }
  .home_banner_bottom h1{
    font-size: 23px;
  }
  .common_social_slider {
    flex-direction: column-reverse;
  }  
}
@media(max-width: 375px){
  header > nav > ul > li:not(:last-child) {
    margin-right: 24px;
  }
  .home_banner_bottom {
    margin: 0 -45px;
  }
  .home_banner_bottom h1 {
    font-size: 22px;
  }
}
@media(max-width: 359px){
  .home_banner_bottom {
    margin: 0 -15px;
  }
  header > nav > ul > li:not(:last-child) {
    margin-right: 18px;
  }
}